<template>
  <div class="rulesOrderBox" v-loading="initLoading">
    <!-- 手机端接单规则 -->
    <div class="iphone-order">
      <!-- 手机端接单规则 -->
      <div class="orderTittle">{{ $t("c156") }}</div>
    </div>
    <!-- 规则内容 -->
    <div class="order-content" v-loading="loading">
      <!-- 接单模式 开关 -->
      <div class="order-model">
        <!-- 开启接单模式 -->
        <div class="model-text">{{ $t("c157") }}</div>
        <!-- 关闭 -->
        <div
          class="isOpenedClass"
          :class="{ isclodesClass: orderSwitch === $t('label.tabpage.closez') }"
        >
          {{ orderSwitch }}
        </div>
        <el-switch v-model="isOpened" @change="changeOpend"></el-switch>
      </div>
      <!-- 开启后 展示的规则内容 -->
      <div class="all-order" v-show="isShowRules">
        <!-- 分配时间阈值 -->
        <div class="allocation">
          <!-- 分配时间阈值 -->
          <div class="allocation-time">{{ $t("c158") }}</div>
          <!-- 已开启 -->
          <div
            class="isOpenedClassTwo"
            :class="{
              isclodesClassTwo:
                assignSwitch === $t('front-electronic-signature-isopen'),
            }"
          >
            {{ assignSwitch }}
          </div>
          <el-switch v-model="isOpenedTwo" @change="changeOpendTwo"></el-switch>
        </div>
        <!-- 分配时间阈值 启用后展示下方 设置 -->
        <div class="time-order" v-show="isShowRulesTwo">
          <!-- 接单时长 -->
          <div class="rules-one">
            <span class="maxTimeClass">
              <!-- 接单时长最大不超过 -->
              <span class="xing">*</span>
              {{ $t("c159") }}
            </span>
            <el-input v-model="maxTime" @blur="endTime"></el-input>
            <el-select v-model="timeTypeValue" @change="chooseTime">
              <el-option
                v-for="item in timeTypesArray"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <div class="minuteClass" v-show="showMessage">
              *{{ showMessageText }}
            </div>
          </div>
          <!-- 逾期后处理方式 -->
          <div class="rules-three">
            <!-- 逾期后处理方式 -->
            <span>{{ $t("c160") }}</span>
            <el-select v-model="processModeValue" @change="processModeMethod">
              <el-option
                v-for="item in processModeArray"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 通知人员 -->
          <div class="rules-four">
            <!-- 同时通知给 -->
            <span>{{ $t("c161") }}</span>
            <!-- 通知人员 类型 -->
            <div class="notceType">
              <el-select
                v-model="notifierValue"
                class="peopleType"
                @change="handleChangeType"
              >
                <el-option
                  v-for="item in notifierArray"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>

            <!-- 通知人员 列表 -->
            <div class="noticeList">
              <el-select
                v-model="choosePeopleIds"
                popper-class="select-down"
                filterable
                multiple
                placeholder=""
                @change="changeSelect"
                @visible-change="visibleSelect"
              >
                <el-option
                  v-for="item in choosePeopleArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  style="width: 325px"
                />
              </el-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 保存 -->
    <div class="fotterBtn">
      <!--        保存-->
      <el-button type="primary" @click="savebtn" :disabled="isDisabled">{{
        $t("label.chatter.save")
      }}</el-button>
    </div>
  </div>
</template>
<script>
import * as rulesOrderApi from "./api";
export default {
  name: "",
  data() {
    return {
      initLoading: false, // 初始化loading
      loading: false, // 保存loading
      isDisabled: true, //保存按钮 是否禁用
      isOpened: true, // 接单模式 开关(默认开启状态)
      orderSwitch: this.$i18n.t("front-electronic-signature-isopen"), //已开启
      isOpenedTwo: false, // 分配时间阈值 开关(默认关闭状态)
      assignSwitch: this.$i18n.t("label.tabpage.closez"), //关闭
      isShowRules: true, //接单模式 设置规则
      isShowRulesTwo: false, //分配时间阈值 设置规则
      maxTime: "", // 接单时长最大不超过
      timeTypeValue: "", // 接单时长 时间单位
      timeTypesArray: [
        {
          value: "Minute",
          label: this.$i18n.t("label.ems.minute"), // 接单时长时间单位 数组
        },
        {
          // 分钟 小时
          value: "Hour",
          label: this.$i18n.t("label.ems.hour"),
        },
      ],
      showMessage: false, //  接单时长不合格 提示语
      showMessageText: "", //  提示语 内容
      processModeValue: "", // 逾期后处理方式
      processModeArray: [
        {
          value: "first",
          label: this.$i18n.t("c162"), // 逾期后处理方式 数组
        },
        {
          //同时通知分配人及被分配人   同时通知并取消派遣
          value: "second",
          label: this.$i18n.t("c163"),
        },
      ],
      notifierValue: "", // 通知人员 类型
      notifierArray: [
        {
          value: "User",
          label: this.$i18n.t("label.user"), // 通知人员 类型 数组
        },
        {
          value: "Role",
          label: this.$i18n.t("label.tabpage.rolez"),
        },
      ],
      choosePeopleIds: [], // 要通知的人员
      choosePeopleArr: [], // 要通知的人员 列表
      chooseUserArr: [], //用户列表
      chooseRoleArr: [], //角色列表
    };
  },
  mounted() {
    // 初始化
    this.orderInit();
  },
  watch: {
    // maxTime(nval, oval) {
    //   if (nval != oval) {
    //     this.isDisabled = false;
    //   }
    // },
  },
  methods: {
    // 初始化
    orderInit() {
      // 用户列表
      this.userList();
      // 角色列表
      this.roleList();
    },
    // 获取接单规则设置 初始化 回显
    getOrdersRule() {
      this.initLoading = true;
      setTimeout(() => {
        let params = {};
        rulesOrderApi
          .getOrdersRule(params)
          .then((res) => {
            if (res.data) {
              //     "111",
              //     this.choosePeopleArr,
              //     this.chooseUserArr,
              //     this.chooseRoleArr
              //   );
              // 接单模式 开关
              this.isOpened = res.data.orderModel === "true" ? true : false;
              if (res.data.orderModel === "true") {
                this.orderSwitch = this.$i18n.t(
                  "front-electronic-signature-isopen"
                ); //已开启
                // 接单模式 开启后 显示设置规则
                this.isShowRules = true;
              } else {
                this.orderSwitch = this.$i18n.t("label.tabpage.closez"); //关闭
                // 接单模式 关闭后 不显示设置规则
                this.isShowRules = false;
              }
              // 分配时间阈值 开关 开关
              this.isOpenedTwo =
                res.data.timeThreshold === "true" ? true : false;
              if (res.data.timeThreshold === "true") {
                this.assignSwitch = this.$i18n.t(
                  "front-electronic-signature-isopen"
                ); //已开启
                // 分配时间阈值 开启后 显示设置规则
                this.isShowRulesTwo = true;
              } else {
                this.assignSwitch = this.$i18n.t("label.tabpage.closez"); //关闭
                // 分配时间阈值 关闭后 不显示设置规则
                this.isShowRulesTwo = false;
              }
              //  最大时间
              this.maxTime = res.data.maxTime;
              // 接单时长
              this.timeTypeValue =
                res.data.timeUnit === this.$i18n.t("label.ems.minute")
                  ? "Minute"
                  : "Hour"; //分钟
              // 逾期后处理方式
              this.processModeValue =
                res.data.overdueSolution === 0 ? "first" : "second";
              // 要通知的人员 类型  默认为 用户
              this.notifierValue = this.notifierArray[0].value;
              // 要通知的人员列表 (选中)
              if (
                res.data.noticeUsers &&
                res.data.noticeUsers != null &&
                res.data.noticeUsers != ""
              ) {
                this.choosePeopleIds = res.data.noticeUsers.split(",");
              } else {
                this.choosePeopleIds = [];
              }

              //要通知的人员 列表 默认 用户
              if (
                res.data.noticeUsers &&
                res.data.noticeUsers != null &&
                res.data.noticeUsers != ""
              ) {
                this.choosePeopleArr = res.data.noticeUsers.includes("User")
                  ? this.chooseUserArr
                  : this.chooseRoleArr;
              } else {
                this.choosePeopleArr = this.chooseUserArr;
              }
              //  this.choosePeopleArr = this.chooseUserArr;
            } else {
              // 接单时长 时间单位默认(分钟)
              this.timeTypeValue = this.timeTypesArray[0].value;
              // 逾期后处理方式 默认为 同时通知分配人及被分配人
              this.processModeValue = this.processModeArray[0].value;
              // 要通知的人员 类型 默认为 用户
              this.notifierValue = this.notifierArray[0].value;
            }
            this.initLoading = false;
          })
          .catch(() => {});
      }, 1000);
    },
    // 接单模式 开关
    changeOpend(dat) {
      this.isDisabled = false;
      if (dat === true) {
        this.orderSwitch = this.$i18n.t("front-electronic-signature-isopen"); //已开启
        // 接单模式 开启后 显示设置规则
        this.isShowRules = true;
      } else {
        this.orderSwitch = this.$i18n.t("label.tabpage.closez"); //关闭
        // 接单模式 关闭后 不显示设置规则
        this.isShowRules = false;
      }
    },
    // 分配时间阈值 开关  isShowRulesTwo
    changeOpendTwo(dat) {
      this.isDisabled = false;
      if (dat === true) {
        this.assignSwitch = this.$i18n.t("front-electronic-signature-isopen"); //已开启
        // 分配时间阈值 开启后 显示设置规则
        this.isShowRulesTwo = true;
      } else {
        this.assignSwitch = this.$i18n.t("label.tabpage.closez"); //关闭
        // 分配时间阈值 关闭后 不显示设置规则
        this.isShowRulesTwo = false;
      }
    },
    // 接单时间 输入
    endTime() {
      this.isDisabled = false;
      // 时间类型 分钟
      if (this.timeTypeValue === "Minute") {
        // 0-60内的正整数数值
        let reg = /^([1-9]|[1-5]\d)$/;
        if (!reg.test(this.maxTime)) {
          this.showMessage = true;
          this.showMessageText = this.$i18n.t("c164"); //请输入0-60内的整数数值
        } else {
          this.showMessage = false;
          this.showMessageText = this.$i18n.t("c164"); //请输入0-60内的整数数值
        }
      } // 时间类型 小时
      else {
        // 0-24内的正整数数值
        let reg1 = /^([1-9]|1[0-9]|2[0-3])$/;
        if (!reg1.test(this.maxTime)) {
          this.showMessage = true;
          this.showMessageText = this.$i18n.t("c165"); //请输入0-24内的整数数值
        } else {
          this.showMessage = false;
          this.showMessageText = this.$i18n.t("c165"); //请输入0-24内的整数数值
        }
      }
    },
    // 接单时间 更改时间单位事件
    chooseTime() {
      //   this.isDisabled = false;
      //   //清空
      //   this.maxTime = "";
      //   this.showMessage = false;
      this.endTime();
    },
    // 逾期后处理方式
    processModeMethod() {
      this.isDisabled = false;
    },
    // 用户请求列表
    userList() {
      let option = {
        type: "User",
      };
      rulesOrderApi
        .searchGroup(option)
        .then((res) => {
          this.chooseUserArr = res.data;
          // 接单规则设置 回显
          this.getOrdersRule();
        })
        .then(() => {});
    },
    // 角色请求列表
    roleList() {
      let option = {
        type: "Role",
      };
      rulesOrderApi
        .searchGroup(option)
        .then((res) => {
          this.chooseRoleArr = res.data;
          // 接单规则设置 回显
          this.getOrdersRule();
        })
        .then(() => {});
    },
    // 通知的人员 类型切换
    handleChangeType(data) {
      //   this.choosePeopleIds = [];
      this.isDisabled = false;
      if (data === "Role") {
        this.choosePeopleArr = this.chooseRoleArr;
      } else {
        this.choosePeopleArr = this.chooseUserArr;
      }
    },
    // 下拉框展开 关闭事件
    visibleSelect(e) {
      // 获取所有的li
      const lis = document.getElementsByClassName("el-select-dropdown__item");
      // 打开下拉框 添加input
      if (e) {
        for (let i = 0; i < lis.length; i++) {
          const element = lis[i];
          const input = document.createElement("input");
          input.style.cssText = "margin: 2px 10px 0 0px;";
          input.type = "checkbox";
          // 根据是否有选中的类名来判断li是否被选中
          if (element.classList.contains("selected")) {
            // 对应复选框也设置被选中
            input.checked = true;
          }
          element.insertBefore(input, element.lastChild);
        }
      } else {
        // 关闭下拉框 移除掉input--否则每次打开添加就是多个input
        for (let i = 0; i < lis.length; i++) {
          const element = lis[i];
          element.removeChild(element.firstChild);
        }
      }
    },

    // 通知人员 列表 更改事件
    changeSelect() {
      this.isDisabled = false;
      const lis = document.getElementsByClassName("el-select-dropdown__item");
      for (let i = 0; i < lis.length; i++) {
        const element = lis[i];
        // 必须使用nextTick 否则拿不到最新的修改后的dom选中状态 也就没法判断
        this.$nextTick(() => {
          // 判断当前的li是否被选中 选中的则设置复选框被选中
          if (element.classList.contains("selected")) {
            element.firstChild.checked = true;
          } else {
            element.firstChild.checked = false;
          }
        });
      }
    },
    // 调取保存接口
    saveOrdersRule() {
      this.loading = true;
      setTimeout(() => {
        let option = {
          overdueBase: 1,
          orderModel: this.isOpened,
          timeThreshold: this.isOpenedTwo,
          maxTime: parseInt(this.maxTime),
          timeUnit:
            this.timeTypeValue === "Minute"
              ? this.$i18n.t("label.ems.minute")
              : this.$i18n.t("label.ems.hour"), //分钟  小时
          overdueSolution: this.processModeValue === "first" ? 0 : 1,
          noticeUsers: this.choosePeopleIds.toString(),
        };
        rulesOrderApi
          .saveOrdersRule(option)
          .then(() => {
            this.loading = false;
            this.$message.success(this.$i18n.t("label.search.saveok")); //保存成功
            this.isDisabled = true;
          })
          .catch(() => {});
      }, 1000);
    },
    // 保存 设置
    savebtn() {
      if (this.showMessage === true) {
        this.$message({
          message: this.$i18n.t("c166"), //请按规则输入最大接单时长
          type: "error",
        });
        return;
      } else if (this.maxTime === "") {
        this.$message({
          message: this.$i18n.t("c167"), //接单时长为必填项
          type: "error",
        });
        return;
      } else {
        this.saveOrdersRule();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-select-dropdown__item.selected::after {
  display: none;
}

.rulesOrderBox {
  .iphone-order {
    border-bottom: solid 1px #dedcda;
    .orderTittle {
      padding: 20px;
      font-weight: bold;
      color: #333333;
    }
  }
  .order-content {
    padding: 24px 20px;
    .order-model {
      display: flex;
      .model-text {
        margin-right: 100px;
      }
      .isOpenedClass {
        margin-right: 10px;
        color: #006dcc;
      }
      .isclodesClass {
        margin-right: 10px;
        color: #afafaf;
      }
    }
    .all-order {
      .allocation {
        display: flex;
        padding: 36px 0 26px 0;
        .allocation-time {
          margin-right: 100px;
          font-weight: bold;
          color: #333333;
        }
        .isOpenedClassTwo {
          margin-right: 10px;
          color: #afafaf;
        }
        .isclodesClassTwo {
          margin-right: 10px;
          color: #006dcc;
        }
      }
      .time-order {
        .rules-one {
          position: relative;
          display: flex;
          align-items: center;
          margin-bottom: 40px;
          font-size: 14px;
          .maxTimeClass {
            display: inline-block;
            width: 140px;
            height: 25px;
            line-height: 25px;
            .xing {
              color: red;
            }
          }
          .el-input {
            width: 328px;
            margin-left: 20px;
          }
          .el-select {
            width: 328px;
            margin-left: 20px;
          }
          .minuteClass {
            position: absolute;
            top: 50px;
            left: 161px;
            font-size: 12px;
            color: #c12218;
          }
        }
        .rules-three {
          display: flex;
          align-items: center;
          margin-bottom: 40px;
          font-size: 14px;
          span {
            display: inline-block;
            width: 140px;
            height: 25px;
            line-height: 25px;
          }
          .el-select {
            width: 328px;
            margin-left: 20px;
          }
        }
        .rules-four {
          display: flex;
          font-size: 14px;
          span {
            display: inline-block;
            width: 140px;
            height: 40px;
            line-height: 40px;
          }
          .notceType {
            .peopleType {
              width: 80px;
              margin-left: 20px;
            }
            ::v-deep .el-input__inner {
              border-radius: 0;
              border-top-left-radius: 4px !important;
              border-bottom-left-radius: 4px !important;
              border-right: none;
              background-color: #eeeeee;
            }
          }
          .noticeList {
            // ::v-deep .select-down {
            //   max-width: 328px !important;
            // }
            // ::v-deep .select-down .el-select-dropdown__item {
            //   width: 200px !important;
            // }
            .el-select {
              width: 328px;
            }
            ::v-deep .el-input__inner {
              border-radius: 0;
              border-top-right-radius: 4px !important;
              border-bottom-right-radius: 4px !important;
            }
          }
        }
      }
    }
  }
  .fotterBtn {
    padding: 143px 0 81px 756px;
    font-size: 14px;
    .el-button {
      width: 80px;
      padding: 9px 15px;
    }
  }
}
</style>