import axios from "@/config/httpConfig";

// 保存设置的接单规则
export function saveOrdersRule(data) {
    return axios.post("/ordersRule/saveOrdersRule", data);
}

// 获取接单规则设置
export function getOrdersRule(data) {
    return axios.post("/ordersRule/getOrdersRule", data);
}

// 搜索用户组
export function searchGroup(data) {
    return axios.post("/share/searchGroup", data);
}
